<template>
  <section :id="id" class="mb-6" v-bind="$attrs">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-2xl sm:text-[32px] leading-[38px] font-semibold">
        {{ title }}
        <span :class="cssMapping[id].highlightTitle">
          {{ " " }}{{ highlightTitle }}
        </span>
      </h2>
      <UButton
        v-if="linkViewMore"
        :to="linkViewMore"
        no-prefetch
        :class="cssMapping[id].button"
      >
        {{ LL.view_more() }}
        <UIcon name="i-heroicons-arrow-right" class="text-inherit" />
      </UButton>
    </div>
    <slot />
  </section>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";

type Props = {
  id: "courses-popular" | "interview-questions" | "posts" | "melydemy" | "testimonial";
  title: string;
  highlightTitle: string;
  linkViewMore?: string;
};

defineProps<Props>();
defineOptions({ inheritAttrs: false });

const { LL } = typesafeI18n();

const cssMapping = {
  "courses-popular": {
    highlightTitle: "text-cyan-500",
    button:
      "text-cyan-500 dark:text-white bg-cyan-50 hover:bg-cyan-500 hover:text-white dark:bg-cyan-700 dark:hover:bg-cyan-600",
  },
  "interview-questions": {
    highlightTitle: "text-fuchsia-500",
    button:
      "text-fuchsia-500 dark:text-white bg-fuchsia-50 hover:bg-fuchsia-500 hover:text-white dark:bg-fuchsia-700 dark:hover:bg-fuchsia-600",
  },
  posts: {
    highlightTitle: "text-pink-500",
    button:
      "text-pink-500 dark:text-white bg-pink-50 hover:bg-pink-500 hover:text-white dark:bg-pink-700 dark:hover:bg-pink-600",
  },
  "melydemy": {
    highlightTitle: "text-red-500",
    button:
      "text-red-500 dark:text-white bg-red-50 hover:bg-red-500 hover:text-white dark:bg-red-700 dark:hover:bg-red-600",
  },
  testimonial: {
    highlightTitle: "text-green-500",
    button: "",
  },
};
</script>
